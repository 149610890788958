body {
  background: #1c2038 !important;
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

main img {
  max-width: 100%;
}
.img_wrapper {
  background: #1c2038b0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.hero_text {
  position: absolute;
  top: 0;
  margin-top: 5rem;
  margin-top: 13rem;
  color: white;
  z-index: 1;
  font-size: 4rem;
  font-weight: bold;
  text-align: center;
}
.card_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 77%;
  margin: 8rem 0;
}

@media (max-width: 426px) {
  main img {
    max-width: fit-content;
    width: 840px;
  }
  .hero_text {
    font-size: 1.5rem;
  }
  .card_container {
    flex-direction: column;
    width: auto;
    padding: 0 5%;
    margin: 3rem 0;
  }
}

.homePage {
  overflow: hidden;
}
