.login_background {
	position: absolute;
	width: 100%;
	height: calc(-53px + 100%);
	overflow: hidden;
}
.login_background img {
	position: absolute;
	max-width: 1550px;
	top: -6rem;
}
.login_background .bg_wrapper {
	position: absolute;
	background: radial-gradient(
		52.11% 164.69% at 100% 0%,
		rgba(0, 0, 0, 0.3) 0%,
		rgba(28, 32, 56, 0.84) 31.81%,
		#1c2038 100%
	);
	width: 100%;
	height: 1400px;
	top: -30px;
}

.login_wrapper {
	padding: 4rem 0;
	display: flex;
	align-items: flex-start;
	justify-content: center;
}
.loginDetails {
	width: 50%;
	z-index: 100;
}

.loginDetails .paymentDetails_heading_wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.paymentDetailMsgs {
	color: red;
}
.loginDetails .loginDetails_heading_wrapper {
	font-size: 1.8rem;
	font-weight: bold;
}
.loginDetails .loginDetails_heading_wrapper .sub_heading {
	font-size: 0.9rem;
	font-weight: normal;
	color: #bebebe;
}

.loginDetails .loginDetails_heading_wrapper .sub_heading a {
	color: #4fc8e9;
}

.social_logins {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
	margin: 10px 0;
}
.social_logins .social_btn {
	border: 1px solid #454d80;
	border-radius: 3px;
	background: #1c2038;
	color: white;
	text-align: center;
	padding: 10px 5px;
	font-size: 12px;
	width: 100%;
	cursor: pointer;
	text-transform: none;
}

.social_logins .social_btn svg {
	margin-right: 5px;
}

.saperator {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #4fc8e9;
	gap: 10px;
}
.saperator .saperator_line {
	height: 1px;
	width: 100%;
	background-color: #bebebe;
	border-radius: 2px;
}

.login_inputs {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}
.input_row {
	display: flex;
	flex-direction: column;
	gap: 5px;
}
.login_input {
	position: relative;
}
.login_input input {
	background: #1c2038;
	border: 1px solid #454d80;
	border-radius: 3px;
	width: 100%;
	outline: none;
	height: 100%;
	padding: 8px 40px 8px 40px;
	color: white;
	font-size: 14px;
}

.login_input svg {
	position: absolute;
	top: 7px;
	left: 10px;
	width: 20px;
}
.login_input svg:last-child {
	position: absolute;
	top: 7px;
	right: 10px;
	left: auto;
	cursor: pointer;
}

.tnc_checkbox {
	color: #8d94be;
}
.tnc_checkbox .MuiFormControlLabel-label {
	font-size: 14px !important;
}

.login_page .confirmation_btn button {
	color: white;
}
.login_page .confirmation_btn button:hover {
	background-color: #379bb7;
}

.terms {
	color: whitesmoke
}

.terms:hover {
	color: rgb(201, 195, 195)
}