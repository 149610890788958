.footer {
  padding: 1.5rem 3%;
  background: #252b48;
  color: #fff !important;
  font-family: Circular Std, sans-serif !important;
  font-weight: 450 !important;
  z-index: 100;
  position: relative;
}
.footer_sections {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.footer_heading {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  background: linear-gradient(180deg, #4fc8e9 99.99%, #ff2552 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.footer_section_main {
  width: 20%;
}
.footer_section {
  margin-bottom: 1rem;
}
.footer_section .footer_section_heading {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  user-select: none;
  color: #ffffff;
}

.footer_section .footer_section_body {
  /* font-family: 'Circular Std'; */
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  color: #bebebe;
  user-select: none;
  cursor: pointer;
}

.footer_buttons {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
.footer_buttons div img {
  max-width: 100px;
  height: 30px;
  cursor: pointer;
}

.footer_footer {
  border-top: 1px solid #ffffff;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}
.footer_footer .footer_terms {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.footer_footer .footer_terms div,
.footer_footer .footer_copyright div {
  user-select: none;
  cursor: pointer;
}
.footer_footer .footer_copyright {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 426px) {
  .footer_section_main {
    width: 50%;
  }
  .footer_heading {
    font-size: 2rem;
  }
  .footer_footer {
    flex-direction: column;
    gap: 1rem;
  }
  .footer_footer .footer_terms {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
  }
  .footer_buttons {
    justify-content: center;
  }
  .footer_buttons div img {
    max-width: inherit;
    height: 54px;
  }
}
