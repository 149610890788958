.cards {
  width: 33.33%;
  min-height: 450px;
  background: #252b48;
  border-radius: 16px;
  padding: 5rem 2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.cards .cards_price {
  position: absolute;
  width: 110px;
  height: 110px;
  /* background-color: white; */
  top: -50px;
  border-radius: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(100deg, #6643c8 20%, #344fa2 90%);
  padding: 1rem;
}

.cards:first-child .cards_price {
  background: linear-gradient(100deg, #378297 10%, #5634a2 90%);
}
.cards:last-child .cards_price {
  background: linear-gradient(100deg, #344fa2 40%, #378297 100%);
}

.cards .cards_price p {
  margin-bottom: 0;
  font-size: 25px;
}
.cards .cards_price span {
  font-size: 12px;
  text-transform: uppercase;
}

.cards .cards_title {
  font-size: 24px;
  text-transform: uppercase;
  border-bottom: 3px solid;
  width: 100%;
  text-align: center;
  border-image-source: linear-gradient(100deg, #6643c8 20%, #344fa2 90%);
  border-image-slice: 1;
  margin-bottom: 1rem;
}

.cards:first-child .cards_title {
  border-image-source: linear-gradient(100deg, #378297 10%, #5634a2 90%);
}
.cards:last-child .cards_title {
  border-image-source: linear-gradient(100deg, #344fa2 40%, #378297 100%);
}

.cards .cards_body {
  font-size: 15px;
  margin-left: 4rem;
  /* text-align: center; */
}

.cards .cards_body div {
  margin: 10px 0;
  color: #bebebe;
  display: flex;
  align-items: center;
  gap: 5px;
}

.cards .cards_body svg {
  width: 20px;
}

.cards .order_btn button {
  background: linear-gradient(100deg, #6643c8 20%, #344fa2 90%);
  border-radius: 7px;
  border: none;
  color: white;
  text-transform: uppercase;
  padding: 0.5rem 1.5rem;
  opacity: 0.9;
}

.cards:first-child .order_btn button {
  background: linear-gradient(100deg, #378297 10%, #5634a2 90%);
}
.cards:last-child .order_btn button {
  background: linear-gradient(100deg, #344fa2 40%, #378297 100%);
}

.cards .order_btn button:hover {
  opacity: 1;
}

@media (max-width: 426px) {
  .cards {
    width: auto;
  }
}
